import { AppSettings } from "@/models/app-settings";
import axios from "axios";

const production = true;
const apiUrl = (production ? 'https://halcyon-api.herokuapp.com/' : 'http://localhost:3000/');
const bearerToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NywiaWF0IjoxNjU2MzQxNjA5fQ.XSa2yd2OMk6NXkEER6qKsCoN9_JVFfkoqbPr9DD4DRY';

const state = () => ({
    showSidebar: false,
    dataConnected: true,
    user: {},
    selectedTool: 'notes',
    authToken: null,
    deviceTypes: [],
    deviceModels: [],
    consumableCategories: [],
    consumableTypes: [],
    consumableConditions: [],
    apiVersion: '',
    lastUserSync: null,
    observationDeviceTypes: [],
    observationQuestions: [],
});
 
const getters = {
    user(state: any){
        return state.user;
    },
    showSidebar(state: any){
        return state.showSidebar;
    },
    dataConnected(state: any){
        return state.dataConnected;
    },
    selectedTool(state: any){
        return state.selectedTool;
    },
    authToken(state: any){
        return state.authToken;
    },
    deviceTypes(state: any){
        return state.deviceTypes;
    },
    deviceModels(state: any){
        return state.deviceModels;
    },
    consumableCategories(state: any){
        return state.consumableCategories;
    },
    consumableTypes(state: any){
        return state.consumableTypes;
    },
    consumableConditions(state: any){
        return state.consumableConditions;
    },
    observationDeviceTypes(state: any){
        return state.observationDeviceTypes;
    },
    observationQuestions(state: any){
        return state.observationQuestions;
    },
};
 
const actions = {
    async storeUser({commit}: any, user: any){
        commit('storeUser', user);
    },
    async revealSidebar({commit}: any){
        commit('setSidebarVisibility', true);
    },
    async hideSidebar({commit}: any){
        commit('setSidebarVisibility', false);
    },
    async updateConnectivity({commit}: any, connected: boolean){
        commit('updateConnectivity', connected);
    },
    async selectTool({commit}: any, tool: string){
        commit('selectTool', tool);
    },
    async saveAuthToken({commit}: any, authToken: string){
        commit('saveAuthToken', authToken);
    },
    async clearAuthToken({commit}: any){
        commit('clearAuthToken');
    },
    async getAppSettings({commit}: any){
        const response = await axios.get(apiUrl + "config", {
            headers: { Authorization: `Bearer ` + bearerToken }
        });
        commit('storeAppSettings', response.data);
    },
};
 
const mutations = {
    storeUser(state: any, user: any){
        state.user = user;
    },
    setSidebarVisibility(state: any, isVisible: boolean){
        state.showSidebar = isVisible;
    },
    updateConnectivity(state: any, connectivity: boolean){
        state.dataConnected = connectivity;
    },
    selectTool(state: any, tool: string){
        state.selectedTool = tool;
    },
    saveAuthToken(state: any, authToken: string){
        state.authToken = authToken;
    },
    clearAuthToken(state: any){
        state.authToken = null;
    },
    storeAppSettings(state: any, appSettingsResponse: AppSettings){
        state.apiVerison = appSettingsResponse.apiVerison;
        state.lastUserSync = appSettingsResponse.lastUserSync;
        state.deviceTypes = appSettingsResponse.deviceTypes;
        state.deviceModels = appSettingsResponse.deviceModels;
        state.consumableCategories = appSettingsResponse.consumableCategories;
        state.consumableTypes = appSettingsResponse.consumableTypes;
        state.consumableConditions = appSettingsResponse.consumableConditions;
        state.observationDeviceTypes = appSettingsResponse.observationDeviceTypes;
        state.observationQuestions = appSettingsResponse.observationQuestions;
    },
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}