import { Observation, ObservationAnswers } from "@/models/database-models";
import APIService from '../api';
const apiService = new APIService();
import { Utils } from '../../store/utils';

const state = () => ({
    selectedObservation: {},
    observationWizard: {}
});
 
const getters = {
    selectedObservation(state: any): Observation {
        return state.selectedObservation;
    },
    observationWizard(state: any): Observation {
        return state.observationWizard;
    },
};
 
const actions = {
    async getObservationsList({commit}: any, engagementId: number) {
        const resp = await apiService.get("observations/" + engagementId);
        if (Utils.isSuccessfulResponse(resp)) {
            commit('engagements/updateObservations', resp.data, { root: true });
        }
    },
    async createObservation({dispatch, commit}: any, createData: { engagementId: number, observationDeviceTypeId: number }) {
        const resp = await apiService.post("observations", createData);
        if (Utils.isSuccessfulResponse(resp)) {
            return resp.data;
        } else {
            if (resp.status === 'queue') {
                const newObservation = resp.data.data;
                const tmpObservation = new Observation(createData.engagementId);
                tmpObservation.observationDeviceTypeId = createData.observationDeviceTypeId;
                const queueObservation = JSON.parse(JSON.stringify(resp.data));
                queueObservation.data = tmpObservation;
                dispatch('queue/addToQueue', queueObservation, { root: true });
                commit('engagements/addObservation', newObservation, { root: true });
                return newObservation;
            }
            return null;
        }
    },
    async updateObservation({dispatch}: any, observation: Observation) {
        const observationId = observation.id;
        const observationToSend = new Observation(observation.engagementId);
        if (observation.name) { observationToSend.name = observation.name; }
        if (observation.pointOfContact) { observationToSend.pointOfContact = observation.pointOfContact; }
        if (observation.observationTypeId) { observationToSend.observationTypeId = observation.observationTypeId; }
        if (observation.status) { observationToSend.status = observation.status; }
        const resp = await apiService.post("observations/" + observationId, observation);
        if (Utils.isSuccessfulResponse(resp)) {
            return resp;
        } else {
            if (resp.status === 'queue') {
                dispatch('queue/addToQueue', resp.data, { root: true });
            }
            return null;
        }
    },
    async selectObservation({commit}: any, observation: Observation) {
        commit('selectObservation', observation);
    },
    async recordObservationAnswers({dispatch, commit}: any, updateData: { answers: Array<ObservationAnswers>, status: number }) {
        if (updateData.answers.length > 0) {
            const resp = await apiService.post("observations/" + updateData.answers[0].observationId + '/answers', {answers: updateData.answers, status: updateData.status });
            if (Utils.isSuccessfulResponse(resp)) {
                return resp;
            } else {
                if (resp.status === 'queue') {
                    dispatch('queue/addToQueue', resp.data, { root: true });
                    commit('updateObservationAnswers', updateData.answers);
                }
                return null;
            }
        } else {
            return null;
        }
    },
    async batchDeleteObservations({commit}: any, observationIds: Array<number>) {
        const resp = await apiService.post("batch/delete/observations", { observationIds });
        if (resp) {
            return resp;
        } else {
            commit('engagements/deleteObservations', observationIds, { root: true });
            return null;
        }
    },
    async updateWizard({commit}: any, wizardValues: any) {
        commit('updateWizard', wizardValues);
    },
};
 
const mutations = {    
    selectObservation(state: any, payload: any){
        state.selectedObservation = payload;
    },
    updateWizard(state: any, payload: any){
        state.observationWizard = payload;
    },
    updateObservationAnswers(state: any, payload: any){
        if (!state.selectedObservation.ObservationAnswers) {
            state.selectedObservation.ObservationAnswers = [];
        }
        payload.forEach((element: ObservationAnswers) => {
            state.selectedObservation.ObservationAnswers[element.observationQuestionId] = element;
        });
    },
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}