import { Consumable, Device, Engagement, Location, Note, Observation } from "@/models/database-models";
import axios from "axios";
import { Network } from '@capacitor/network';

const production = true;
const apiUrl = (production ? 'https://halcyon-api.herokuapp.com/' : 'http://localhost:3000/');
const bearerToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NywiaWF0IjoxNjU2MzQxNjA5fQ.XSa2yd2OMk6NXkEER6qKsCoN9_JVFfkoqbPr9DD4DRY';

const state = () => ({
    engagementList: [],
    selectedEngagement: {},
});
 
const getters = {
    engagementsList(state: any): Array<Engagement> {
        return state.engagementList;
    },
    selectedEngagement(state: any): Engagement {
        return state.selectedEngagement;
    },
    selectedEngagementObservations(state: any): Array<Observation> {
        return state.selectedEngagement.observations;
    },
    selectedEngagementDevices(state: any): Array<Device> {
        return state.selectedEngagement.devices;
    },
    selectedEngagementConsumables(state: any): Array<Device> {
        return state.selectedEngagement.consumables;
    },
};
 
const actions = {
    async fetchEngagements({commit}: any){
        const status = await Network.getStatus();
        if (status.connected) {
            const response = await axios.get(apiUrl + "engagements", {
                headers: { Authorization: `Bearer ` + bearerToken }
            });
            commit('storeEngagements', response.data);
            return response;
        } else {
            return null;
        }
    },
    async getEngagementDetails({commit}: any, engagementId: number){
        const status = await Network.getStatus();
        if (status.connected) {
            const response = await axios.get(apiUrl + "engagements/" + engagementId, {
                headers: { Authorization: `Bearer ` + bearerToken }
            });
            commit('storeEngagementDetails', response.data);
            return response;
        } else {
            return null;
        }
    },
};
 
const mutations = {
    storeEngagements(state: any, payload: any){
        state.engagementList = payload.engagements;
    },
    storeEngagementDetails(state: any, payload: any){
        state.selectedEngagement = payload;
    },
    async refreshEngagementDetails(state: any){
        if (state.selectedEngagement && state.selectedEngagement.engagement) {
            const response = await axios.get(apiUrl + "engagements/" + state.selectedEngagement.engagement.id, {
                headers: { Authorization: `Bearer ` + bearerToken }
            });
            state.selectedEngagement = response.data;
        }
    },
    
    // ENGAGEMENT DEVICES
    updateDevices(state: any, payload: any){
        state.selectedEngagement.devices = payload.devices;
        state.selectedEngagement.consumables = payload.consumables;
    },
    addDevice(state: any, device: Device){
        state.selectedEngagement.devices.push(device);
    },
    updateDevice(state: any, updateData: Device){
        state.selectedEngagement.devices.forEach((device: Device) => {
            if (device.id === updateData.id) {
                device = updateData;
            }
        });
    },
    addConsumable(state: any, consumables: Consumable){
        state.selectedEngagement.consumables.push(consumables);
    },
    deleteDevice(state: any, deviceId: any){
        let deleteIndex = -1;
        state.selectedEngagement.devices.forEach((device: Device, index: number) => {
            if (device.id === deviceId) {
                deleteIndex = index;
            }
        });
        if (deleteIndex > -1) {
            state.selectedEngagement.devices.splice(deleteIndex, 1);
        }
    },
    deleteDevices(state: any, deviceIds: Array<number>){
        const deleteIndexes = new Array<number>();
        state.selectedEngagement.devices.forEach((device: Device, index: number) => {
            deviceIds.forEach( (devsToDelete: number) => {
                if (device.id === devsToDelete) {
                    deleteIndexes.push(index);
                }
            });
        });
        deleteIndexes.reverse();
        deleteIndexes.forEach( (delIndex: number) => {
            state.selectedEngagement.devices.splice(delIndex, 1);
        });
    },
    deleteConsumable(state: any, consumableId: any){
        let deleteIndex = -1;
        state.selectedEngagement.consumables.forEach((consumable: Consumable, index: number) => {
            if (consumable.id === consumableId) {
                deleteIndex = index;
            }
        });
        if (deleteIndex > -1) {
            state.selectedEngagement.consumables.splice(deleteIndex, 1);
        }
    },
    deleteConsumables(state: any, consumableIds: Array<number>){
        const deleteIndexes = new Array<number>();
        state.selectedEngagement.consumables.forEach((consumable: Device, index: number) => {
            consumableIds.forEach( (consToDelete: number) => {
                if (consumable.id === consToDelete) {
                    deleteIndexes.push(index);
                }
            });
        });
        deleteIndexes.reverse();
        deleteIndexes.forEach( (delIndex: number) => {
            state.selectedEngagement.consumables.splice(delIndex, 1);
        });
    },

    // ENGAGEMENT LOCATIONS
    updateLocations(state: any, payload: any){
        state.selectedEngagement.locations = payload.locations;
    },
    addLocation(state: any, location: Location){
        state.selectedEngagement.locations.push(location);
    },
    deleteLocation(state: any, locationId: any){
        let deleteIndex = -1;
        state.selectedEngagement.locations.forEach((location: Location, index: number) => {
            if (location.id === locationId) {
                deleteIndex = index;
            }
        });
        if (deleteIndex > -1) {
            state.selectedEngagement.locations.splice(deleteIndex, 1);
        }
    },

    // ENGAGEMENT NOTES
    updateNotes(state: any, payload: any){
        state.selectedEngagement.notes = payload.notes;
    },
    addNote(state: any, note: Note){
        state.selectedEngagement.notes.push(note);
    },
    deleteNote(state: any, noteId: any){
        let deleteIndex = -1;
        state.selectedEngagement.notes.forEach((note: Note, index: number) => {
            if (note.id === noteId) {
                deleteIndex = index;
            }
        });
        if (deleteIndex > -1) {
            state.selectedEngagement.notes.splice(deleteIndex, 1);
        }
    },

    // ENGAGEMENT OBSERVATIONS
    updateObservations(state: any, payload: any){
        state.selectedEngagement.observations = payload.observations;
    },
    deleteObservations(state: any, observationIds: Array<number>){
        const deleteIndexes = new Array<number>();
        state.selectedEngagement.observations.forEach((observation: Observation, index: number) => {
            observationIds.forEach( (obsToDelete: number) => {
                if (observation.id === obsToDelete) {
                    deleteIndexes.push(index);
                }
            });
        });
        deleteIndexes.reverse();
        deleteIndexes.forEach( (delIndex: number) => {
            state.selectedEngagement.observations.splice(delIndex, 1);
        });
    },
    addObservation(state: any, observation: Observation){
        state.selectedEngagement.observations.push(observation);
    },
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}