import { Location } from "@/models/database-models";
import { SortOrderNode } from "@/models/sort-order-node";
import APIService from '../api';
const apiService = new APIService();
import { Utils } from '../../store/utils';

const state = () => ({
    selectedLocation: {},
});
 
const getters = {
    selectedLocation(state: any): Location {
        return state.selectedLocation;
    },
};
 
const actions = {
    async getLocationsList({commit}: any, engagementId: number) {
        const resp = await apiService.get("locations/" + engagementId);
        if (Utils.isSuccessfulResponse(resp)) {
            commit('engagements/updateLocations', resp.data, { root: true });
        }
    },
    async addLocation({commit, dispatch}: any, location: Location) {
        delete location.createdAt;
        delete location.updatedAt;
        delete location.deletedAt;
        const resp = await apiService.post("locations", location);
        if (Utils.isSuccessfulResponse(resp)) {
            return resp;
        } else {
            if (resp.status === 'queue') {
                dispatch('queue/addToQueue', resp.data, { root: true });
                commit('engagements/addLocation', location, { root: true });
            }
            return null;
        }
    },
    async updateLocation({dispatch}: any, location: Location) {
        const id = location.id;
        delete location.id;
        delete location.createdAt;
        delete location.updatedAt;
        delete location.deletedAt;
        const resp = await apiService.post("locations/" + id, location);
        if (Utils.isSuccessfulResponse(resp)) {
            return resp;
        } else {
            if (resp.status === 'queue') {
                dispatch('queue/addToQueue', resp.data, { root: true });
            }
            return null;
        }
    },
    async deleteLocation({dispatch, commit}: any, locationId: number) {
        const resp = await apiService.delete("locations/" + locationId);
        if (Utils.isSuccessfulResponse(resp)) {
            return resp;
        } else {
            if (resp.status === 'queue') {
                dispatch('queue/addToQueue', resp.data, { root: true });
                commit('engagements/deleteLocation', locationId, { root: true });
            }
            return null;
        }
    },
    async selectLocation({commit}: any, location: Location) {
        commit('selectLocation', location);
    },
    async sortLocations({dispatch}: any, updateData: { engagementId: number, locationSortOrder: Array<SortOrderNode> }) {
        const orderUpdate = { locations: updateData.locationSortOrder };
        const resp = await apiService.post("locations/" + updateData.engagementId + "/sort", orderUpdate);
        if (Utils.isSuccessfulResponse(resp)) {
            return resp;
        } else {
            if (resp.status === 'queue') {
                dispatch('queue/addToQueue', resp.data, { root: true });
            }
            return null;
        }
    },

};
 
const mutations = {
    selectLocation(state: any, payload: any){
        state.selectedLocation = payload;
    },
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}