export interface BaseModel {
    id?: number | string,
    createdAt?: string,
    updatedAt?: string,
    deletedAt?: string,
}

export interface Engagement extends BaseModel {
    name: string
}

export interface Note extends BaseModel {
    title?: string,
    engagementId: number,
    body: string,
    locationId?: number | string
}
export class Note implements Note {
    constructor(engagementId: number) {
        this.engagementId = engagementId;
    }
}

export interface Location extends BaseModel {
    name: string,
    engagementId: number,
    sortOrder: number,
    notes?: string
}
export class Location implements Location {
}

export interface DeviceTypes extends BaseModel {
    name: string,
    sortOrder: number,
}

export interface DeviceModels extends BaseModel {
    name: string,
    manufacturer: string,
    deviceTypeId: number,
}

export interface Device extends BaseModel {
    deviceModelId: number,
    locationId: number | string,
    locationDescription: string,
    serialNumber: string,
    yearManufactured: number,
    bProximityCompliance: boolean,
    bUnderWarranty: boolean,
    bNeedsAttention: boolean,
    bAuditingDeviceConsumables: boolean,
    bPacing: boolean,
    bBiphasic: boolean,
    b12Lead: boolean,
    bSPO2: boolean,
    bETCO2: boolean,
    bCPRHelp: boolean,
    bIBP: boolean,
    bNIBP: boolean,
    bDEFIB: boolean,
    bWifi: boolean,
    bRemoteMonitoring: boolean,
    'deviceModel.name'?: string,
    'deviceModel.manufacturer'?: string,
    'deviceModel.deviceType.id'?: number,
    'deviceModel.deviceType.name'?: string
}
export class Device implements Device {
    constructor(locationId: number) {
        this.locationId = locationId;
        this.bProximityCompliance = false;
        this.bUnderWarranty = false;
        this.bNeedsAttention = false;
        this.bAuditingDeviceConsumables = false;
        this.bPacing = false;
        this.bBiphasic = false;
        this.b12Lead = false;
        this.bSPO2 = false;
        this.bETCO2 = false;
        this.bCPRHelp = false;
        this.bIBP = false;
        this.bNIBP = false;
        this.bDEFIB = false;
        this.bWifi = false;
        this.bRemoteMonitoring = false;
    }
}


export interface Consumable extends BaseModel {
    model: string,
    locationId: number | string,
    consumableTypeId: number,
    consumableCategoryId: number,
    currentConditionId: number,
    vendor: string,
    quantity: number,
    quantityNotes: string,
    bMultipleManufacturers: boolean,
    bExpired: boolean,
    bNeedsToBeReplaced: boolean,
    bNeedsImmediateAction: boolean,
    'consumableType.name'?: string,
    'consumableCategory.name'?: string,
    'currentCondition.name'?: string,
}
export class Consumable implements Consumable {
    constructor(locationId: number) {
        this.locationId = locationId;
        this.bMultipleManufacturers = false;
        this.bExpired = false;
        this.bNeedsToBeReplaced = false;
        this.bNeedsImmediateAction = false;
    }
}

export interface ConsumableCategories extends BaseModel {
    name: string,
}
export interface ConsumableConditions extends BaseModel {
    name: string,
    sortOrder: number,
}
export interface ConsumableTypes extends BaseModel {
    name: string,
}

export interface Observation extends BaseModel {
    engagementId: number,
    name?: string,
    observationDeviceTypeId?: number,
    pointOfContact?: string,
    observationTypeId?: number,
    status?: number,
    statusAdjusted?: string
    observationDeviceTypeName?: string
    ObservationAnswers?: Array<ObservationAnswers>
    guid?: string
}
export class Observation implements Observation {
    constructor(engagementId: number) {
        this.engagementId = engagementId;
    }
}

export interface ObservationDeviceTypes extends BaseModel {
    title: string,
    slug: string,
    iconImageUrl: string,
    sortOrder: number,
}
export interface ObservationQuestions extends BaseModel {
    observationDeviceTypeId: number,
    questionText: string,
    questionTypeId: number,
    questionOptions: string,
    helperText: string,
    allowNote: number,
    isRequired: number,
    priority: number,
    sortOrder: number,
    showNote?: boolean,
    value?: string,
    noteValue?: string,
    observationObjectColumnTitle?: string
}
export class ObservationQuestions implements ObservationQuestions {
    constructor(questionText: string, questionTypeId: number, helperText: string, isRequired: number, observationObjectColumnTitle?: string, questionOptions?: string) {
        this.questionText = questionText;
        this.questionTypeId = questionTypeId;
        this.helperText = helperText;
        this.isRequired = isRequired;
        this.observationObjectColumnTitle = observationObjectColumnTitle;
        if (questionOptions) {
            this.questionOptions = questionOptions;
        }

    }
}
export interface ObservationAnswers extends BaseModel {
    observationId: number,
    observationQuestionId: number | string,
    answer: any,
    note?: string,
    observationObjectColumnTitle?: string
}
export class ObservationAnswers implements ObservationAnswers {
    constructor(observationId: number, observationQuestionId: number | string, observationQuestionValue: any) {
        this.observationId = observationId;
        this.observationQuestionId = observationQuestionId;
        this.answer = observationQuestionValue;
    }
}

export enum DeviceTypeIDs {
    Defibrillators = 1,
    AED = 2,
    Ventilators = 3,
    Consumables = 4,
    Temperature = 5
  }