import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { toastController, ToastButton } from '@ionic/vue';
import { closeOutline, checkmarkCircle, hourglassOutline, alertCircle, closeCircle } from 'ionicons/icons';

export class Utils {
    static formatDate(dateString: string, formatString: string) {
        return moment(dateString).format(formatString);
    }
    static currentDateString() {
        const todaysDate = new Date();
        return moment(todaysDate).format('YYYY-MM-DD-HHmm');
    }
    static guid(): string {
        return uuidv4();
    }
    static isSuccessfulResponse(resp: any) {
        return (resp && resp.status && resp.status > 199 && resp.status < 300);
    }

    static async showToast(message: string, toastMode: ToastMode, buttonsOverride?: Array<ToastButton>) {
        let btns = new Array<ToastButton>();
        let cssClass = 'toast-zoll';
        switch (toastMode) {
            case ToastMode.SUCCESS:
                cssClass += ' toastbtn-success';
                btns.push({
                    side: 'start',
                    icon: checkmarkCircle
                });
                break;
            case ToastMode.ERROR:
                cssClass += ' toastbtn-error';
                btns.push({
                    side: 'start',
                    icon: closeCircle
                });
                break;
            case ToastMode.ATTENTION:
                cssClass += ' toastbtn-attention';
                btns.push({
                    side: 'start',
                    icon: alertCircle
                });
                break;
            case ToastMode.INFO:
                cssClass += ' toastbtn-neutral';
                btns.push({
                    side: 'start',
                    icon: hourglassOutline
                });
                break;                
            default: 
                break;
        }
        btns.push({
            side: 'end',
            icon: closeOutline,
            role: 'cancel'
        });
        if (buttonsOverride) {
            btns = buttonsOverride;
        }
        const toast = await toastController.create({ message: message, color: 'dark', duration: 2000, cssClass: cssClass, buttons: btns});
        toast.present();
    }
}

export enum ToastMode {
    SUCCESS = 1,
    ERROR = 2,
    ATTENTION = 3,
    INFO = 4
}