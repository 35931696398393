import { GuidLibraryNode, ProcessQueueResponse, QueueNode, QueueNodeMethod } from '@/models/queue-node';
import { Network } from '@capacitor/network';
import { Preferences } from '@capacitor/preferences';

import APIService from '../api';
const apiService = new APIService();
import { Utils } from '../utils';

const state = () => ({
    bearerToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NywiaWF0IjoxNjU2MzQxNjA5fQ.XSa2yd2OMk6NXkEER6qKsCoN9_JVFfkoqbPr9DD4DRY',
    selectedNote: {},
    queue: new Array<QueueNode>(),
    guidLibrary: new Array<GuidLibraryNode>(),
    queueSemaphor: true
});
 
const getters = {
    queue(state: any): Array<QueueNode> {
        return state.queue;
    },
    guidLibrary(state: any): Array<GuidLibraryNode> {
        return state.guidLibrary;
    },
};
 
const actions = {
    async loadQueue(state: any) {
        const { value  } = await Preferences.get({ key: 'queue' });
        if (value) {
            state.queue = JSON.parse(value);
        }
    },
    async addToQueue(state: any, payload: any) {
        await state.commit('addToQueue', payload);
        await state.dispatch('saveQueue');
        if (payload.guid) {
            await state.commit('addGuid', payload.guid);
            await state.dispatch('saveGuidLibrary');
        }
    },
    async saveQueue(state: any) {
        await Preferences.set({
            key: 'queue',
            value: JSON.stringify([state.queue]),
        });
    },

    async loadGuidLibrary(state: any) {
        const { value  } = await Preferences.get({ key: 'guidLibrary' });
        if (value) {
            state.guidLibrary = JSON.parse(value);
        }
    },
    async saveGuidLibrary(state: any) {
        await Preferences.set({
            key: 'guidLibrary',
            value: JSON.stringify(state.guidLibrary),
        });
    },

    async processQueue({commit, dispatch, state}: any) {
        await dispatch('loadQueue');

        console.log("SYNC QUEUE", state.queue);
        console.log("SYNC GUID LIBRARY", state.guidLibrary);
        if (state.queue.length === 0) {
            return ProcessQueueResponse.NOSYNCNEEDED;
        }
        if (state.queueSemaphor) {
            state.queueSemaphor = false;
            const status = await Network.getStatus();
            if (status.connected) {
                const nodesToRemove = [];
                for (let i = 0; i < state.queue.length; i++) {
                        const node = state.queue[i];
        
                        // GET
                        if (node.method === QueueNodeMethod.GET) {
                            // CHECK FOR SYNC GUIDS
                            state.guidLibrary.forEach( (guidNode: GuidLibraryNode) => {
                                if (guidNode.id) {
                                    if (node.url.indexOf(guidNode.guid) > -1) {
                                        node.url  = node.url.replace(guidNode.guid, guidNode.id.toString());
                                    }
                                }
                            });
    
                            // RUN API CALL
                            const resp = await apiService.get(node.url);
                            if (resp) {
                                nodesToRemove.push(state.queue.indexOf(node));
                            }
                        }
        
                        // DELETE
                        if (node.method === QueueNodeMethod.DELETE) {
                            // CHECK FOR SYNC GUIDS
                            state.guidLibrary.forEach( (guidNode: GuidLibraryNode) => {
                                if (guidNode.id) {
                                    if (node.url.indexOf(guidNode.guid) > -1) {
                                        node.url = node.url.replace(guidNode.guid, guidNode.id.toString());
                                    }
                                }
                            });
                            
                            // RUN API CALL
                            const resp = await apiService.delete(node.url);
                            if (resp) {
                                nodesToRemove.push(state.queue.indexOf(node));
                            }
                        }
        
                        // POST
                        if (node.method === QueueNodeMethod.POST) {
                            // REMOVE GUID FROM ID
                            const guidId = node.guid;
                            delete node.data.id;
    
                            // CHECK FOR SYNC GUIDS
                            state.guidLibrary.forEach( (guidNode: GuidLibraryNode) => {
                                if (guidNode.id) {
                                    // POST URL
                                    if (node.url.indexOf(guidNode.guid) > -1) {
                                        node.url = node.url.replace(guidNode.guid, guidNode.id.toString());
                                    }
                                    // POST DATA
                                    const dataKeys = Object.keys(node.data);
                                    dataKeys.forEach( (dKey) => {
                                        if (Array.isArray(node.data[dKey])) {
                                            node.data[dKey].forEach( (dKeyNode: any) => {
                                                const deepDataKeys = Object.keys(dKeyNode);
                                                deepDataKeys.forEach( (deepNodeKey) => {
                                                    if (dKeyNode[deepNodeKey] === guidNode.guid) {
                                                        dKeyNode[deepNodeKey] = guidNode.id;
                                                    }
                                                });
                                            });
                                        } else {
                                            if (node.data[dKey] === guidNode.guid) {
                                                node.data[dKey] = guidNode.id;
                                            }
                                        }
                                    });
                                }
                            });
                            const resp = await apiService.post(node.url, node.data);
                            if (resp && Utils.isSuccessfulResponse(resp)) {
                                const newObject = resp.data;
                                if (newObject.id) {
                                    state.guidLibrary.forEach( (node: any) => {
                                        if (node.guid === guidId) {
                                            node.id = newObject.id;
                                        }
                                    })
                                }
                                nodesToRemove.push(state.queue.indexOf(node));
                            }
                        }
                }
                if (nodesToRemove.length > 0) {
                    nodesToRemove.reverse();
                    nodesToRemove.forEach( (node: number) => {
                        state.queue.splice(node, 1);
                    });
                }
            }
            state.queueSemaphor = true;
            if (state.queue.length === 0) {
                await dispatch('saveQueue');
                commit('engagements/refreshEngagementDetails', null, { root: true });
                return ProcessQueueResponse.COMPLETED;
            } else {
                return ProcessQueueResponse.NOCONNECTION;
            }
        } else {
            return ProcessQueueResponse.INPROCESS;
        }
    },
};
 
const mutations = {
    addToQueue(state: any, payload: any){
        state.queue.push(payload);
    },
    addGuid(state: any, guid: string){
        state.guidLibrary.push({ guid });
    },
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}