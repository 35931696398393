import { QueueNodeMethod } from '@/models/queue-node';
import { Network } from '@capacitor/network';
import axios from "axios";
import { Utils } from '../store/utils';

const production = true; 
const apiUrl = (production ? 'https://halcyon-api.herokuapp.com/' : 'http://localhost:3000/');
const bearerToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NywiaWF0IjoxNjU2MzQxNjA5fQ.XSa2yd2OMk6NXkEER6qKsCoN9_JVFfkoqbPr9DD4DRY';

export default class APIService {
    async get(url: string) {
        const status = await Network.getStatus();
        if (status.connected) {
            const resp = await axios.get(apiUrl + url, {
                headers: { Authorization: `Bearer ` + bearerToken }
            });
            return resp;
        } else {
            return { status: 'queue', data: { method: QueueNodeMethod.GET, url: url, data: null } };
        }
    }
    async post(url: string, payload: any) {
        const status = await Network.getStatus();
        if (status.connected) {
            const resp = await axios.post(apiUrl + url, payload, {
                headers: { Authorization: `Bearer ` + bearerToken }
            });
            return resp;
        } else {
            const newGuid = Utils.guid();
            if (!payload.id) { payload.id = newGuid; }
            return { status: 'queue', data: { method: QueueNodeMethod.POST, url: url, data: payload, guid: newGuid } };
        }
    }
    async delete(url: string) {
        const status = await Network.getStatus();
        if (status.connected) {
            const resp = await axios.delete(apiUrl + url, {
                headers: { Authorization: `Bearer ` + bearerToken }
            });
            return resp;
        } else {
            return { status: 'queue', data: { method: QueueNodeMethod.DELETE, url: url, data: null } };
        }
    }
}