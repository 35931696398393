import settingsModule from './modules/settings';
import engagementsModule from './modules/engagements';
import devicesModule from './modules/devices';
import locationsModule from './modules/locations';
import notesModule from './modules/notes';
import observationsModule from './modules/observations';
import queueModule from './modules/queue';
import { createStore } from 'vuex';
 
const store = createStore({
    modules:{
        settings: settingsModule,
        engagements: engagementsModule,
        devices: devicesModule,
        locations: locationsModule,
        notes: notesModule,
        observations: observationsModule,
        queue: queueModule
    }
});
 
export default store;