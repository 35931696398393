import { Device, Consumable } from "@/models/database-models";
import { ImportExportDevice } from "@/models/import-export-device";
import APIService from '../api';
const apiService = new APIService();
import { Utils } from '../../store/utils';

const state = () => ({
    selectedDevice: {},
    selectedConsumable: {},
});
 
const getters = {
    selectedDevice(state: any): Device {
        return state.selectedDevice;
    },
    selectedConsumable(state: any): Consumable {
        return state.selectedConsumable;
    },
};
 
const actions = {
    async getDevicesList({commit}: any, engagementId: number) {
        const resp = await apiService.get("devices/" + engagementId);
        if (resp) {
            commit('engagements/updateDevices', resp.data, { root: true });
        }
    },

    async saveDevice({commit, dispatch}: any, device: Device) {
        const id = device.id;
        delete device.id;
        delete device.createdAt;
        delete device.updatedAt;
        delete device.deletedAt;
        delete device['deviceModel.name'];
        delete device['deviceModel.manufacturer'];
        delete device['deviceModel.deviceType.id'];
        delete device['deviceModel.deviceType.name'];
        
        const resp = await apiService.post("devices" + (id ? '/' + id : ''), device);
        if (Utils.isSuccessfulResponse(resp)) {
            return resp;
        } else {
            if (resp.status === 'queue') {
                dispatch('queue/addToQueue', resp.data, { root: true });
                if (!id) {
                    commit('engagements/addDevice', device, { root: true });
                }
            }
            return null;
        }
    },
    async selectDevice({commit}: any, device: Device) {
        commit('selectDevice', device);
    },
    async batchEditDevices(state: any, updateData: { deviceIds: Array<number>, fieldList: Array<{ fieldId: number, fieldValue: any }> }) {
        const resp = await apiService.post("batch/edit/devices", updateData);
        return resp;
    },
    async batchDeleteDevices({commit}: any, deviceIds: Array<number>) {
        const resp = await apiService.post("batch/delete/devices", { deviceIds });
        if (resp) {
            return resp;
        } else {
            commit('engagements/deleteDevices', deviceIds, { root: true });
            return null;
        }
    },
    async batchDuplicateDevices(state: any, deviceIds: Array<number>) {
        const resp = await apiService.post("batch/duplicate/devices", { deviceIds });
        return resp;
    },
    async batchImportDevices(state: any, updateData: { locationId: number, devices: Array<ImportExportDevice> }) {
        const resp = await apiService.post("batch/import/devices", updateData);
        return resp;
    },

    async saveConsumable({commit, dispatch}: any, consumable: Consumable) {
        const id = consumable.id;
        delete consumable.id;
        delete consumable.createdAt;
        delete consumable.updatedAt;
        delete consumable.deletedAt;
        delete consumable['consumableType.name'];
        delete consumable['consumableCategory.name'];
        delete consumable['currentCondition.name'];
        const resp = await apiService.post("consumables" + (id ? '/' + id : ''), consumable);
        if (Utils.isSuccessfulResponse(resp)) {
            return resp;
        } else {
            if (resp.status === 'queue') {
                dispatch('queue/addToQueue', resp.data, { root: true });
                if (!id) {
                    commit('engagements/addConsumable', consumable, { root: true });
                }
            }
            return null;
        }
    },
    async selectConsumable({commit}: any, consumable: Consumable) {
        commit('selectConsumable', consumable);
    },
    async batchEditConsumables(state: any, updateData: { consumableIds: Array<number>, fieldList: Array<{ fieldId: number, fieldValue: any }> }) {
        const resp = await apiService.post("batch/edit/consumables", updateData);
        return resp;
    },
    async batchDeleteConsumables({commit}: any, consumableIds: Array<number>) {
        const resp = await apiService.post("batch/delete/consumables", { consumableIds });
        if (resp) {
            return resp;
        } else {
            commit('engagements/deleteConsumables', consumableIds, { root: true });
            return null;
        }
        return resp;
    },
    async batchDuplicateConsumables(state: any, consumableIds: Array<number>) {
        const resp = await apiService.post("batch/duplicate/consumables", { consumableIds });
        return resp;
    },
};
 
const mutations = {
    selectDevice(state: any, payload: Device){
        state.selectedDevice = payload;
    },

    selectConsumable(state: any, payload: Consumable){
        state.selectedConsumable = payload;
    },
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}