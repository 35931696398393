export enum QueueNodeMethod {
  GET = 1,
  POST = 2,
  DELETE = 3,
}

export interface QueueNode {
  method: QueueNodeMethod,
  url: string,
  data: any
}

export interface GuidLibraryNode {
  guid: string,
  id: number | null
}

export enum ProcessQueueResponse {
  NOCONNECTION = 1,
  INPROCESS = 2,
  COMPLETED = 3,
  NOSYNCNEEDED = 4
}