import { Note } from "@/models/database-models";
import APIService from '../api';
const apiService = new APIService();
import { Utils } from '../../store/utils';

const state = () => ({
    selectedNote: {},
});
 
const getters = {
    selectedNote(state: any): Note {
        return state.selectedNote;
    },
};
 
const actions = {
    async getNoteList({commit}: any, engagementId: number) {
        const resp = await apiService.get("notes/" + engagementId);
        if (Utils.isSuccessfulResponse(resp)) {
            commit('engagements/updateNotes', resp.data, { root: true });
        }
    },
    async saveNote({commit, dispatch}: any, note: Note) {
        const id = note.id;
        delete note.id;
        delete note.createdAt;
        delete note.updatedAt;
        delete note.deletedAt;
        const resp = await apiService.post("notes" + (id ? '/' + id : ''), note);
        if (Utils.isSuccessfulResponse(resp)) {
            return resp;
        } else {
            if (resp.status === 'queue') {
                dispatch('queue/addToQueue', resp.data, { root: true });
                if (!id) {
                    commit('engagements/addNote', note, { root: true });
                }
            }
            return null;
        }
    },
    async deleteNote({commit, dispatch}: any, deleteData: { engagementId: number, noteId: number }) {
        const resp = await apiService.delete("notes/" + deleteData.noteId);
        if (Utils.isSuccessfulResponse(resp)) {
            return resp;
        } else {
            if (resp.status === 'queue') {
                dispatch('queue/addToQueue', resp.data, { root: true });
                commit('engagements/deleteNote', deleteData.noteId, { root: true });
            }
            return null;
        }
    },
    async selectNote({commit}: any, note: Note) {
        commit('selectNote', note);
    },
    async assignLocationToNote({commit}: any, updateData: { engagementId: number, noteId: number, locationId: number }) {
        if (updateData.noteId) {
            await apiService.post("notes/" + updateData.noteId + "/location", { locationId: updateData.locationId });
            commit('addLocationToNote', updateData.locationId);
        } else {
            commit('addLocationToNote', updateData.locationId);
        }
    },
};
 
const mutations = {
    selectNote(state: any, payload: any){
        state.selectedNote = payload;
    },
    addLocationToNote(state: any, payload: any){
        state.selectedNote.locationId = payload;
    },
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}