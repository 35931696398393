import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        path: '/',
        redirect: '/engagements'
      },
      {
        path: '/engagements',
        component: () => import ('../views/EngagementsList.vue')
      },
      {
        path: '/notes',
        component: () => import ('../views/NotesList.vue')
      },
      {
        path: '/notes/addedit',
        component: () => import ('../views/NotesAddEdit.vue')
      },
      {
        path: '/locations',
        component: () => import ('../views/LocationsList.vue')
      },
      {
        path: '/devices',
        component: () => import ('../views/DevicesList.vue')
      },
      {
        path: '/devices/addedit',
        component: () => import ('../views/DevicesAddEdit.vue')
      },
      {
        path: '/consumables/addedit',
        component: () => import ('../views/ConsumablesAddEdit.vue')
      },
      {
        path: '/observations',
        component: () => import ('../views/ObservationsList.vue')
      },
      {
        path: '/observations/start',
        component: () => import ('../views/ObservationsStart.vue')
      },
    ],
  },
  {
    path: '/observations/wizard',
    component: () => import ('../views/ObservationWizard.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
