
  import { IonApp, IonRouterOutlet, alertController } from '@ionic/vue';
  import { defineComponent } from 'vue';
  import { mapGetters, mapActions } from 'vuex';
  import { Network } from '@capacitor/network';
  import { StatusBar, Style } from '@capacitor/status-bar';
  import { Utils, ToastMode } from './store/utils';

  export default defineComponent({
    name: 'App',
    components: {
      IonApp, 
      IonRouterOutlet, 
    },
  data() {
    return {
      connectivityIssueShown: false
    }
  },
  computed:{
    ...mapGetters('engagements', {
      selectedEngagement: 'selectedEngagement',
    }),
    ...mapGetters('queue', {
      queue: 'queue',
    })
  },
    async created() {
      await this.getAppSettings();
      await this.processQueue();
      this.storeUser({ name: 'Rob Fine', email: 'rfine@seamgen.com' });

      Network.addListener('networkStatusChange', async (status) => {
        this.updateConnectivity(status.connected);
        if (status.connected) {
          Utils.showToast('Connection restored. All data will be synched to server.', ToastMode.SUCCESS);
          await this.processQueue();
        } else {
          if (this.connectivityIssueShown) {
          Utils.showToast('Data connectivity lost. All data will be stored locally and synched when data is restored.', ToastMode.ATTENTION);
          } else {
            this.connectivityIssueShown = true;
            const alert = await alertController
            .create({
              header: 'Connection Issue',
              subHeader: '',
              message: 'Your device has lost data connectivity to the server. All your data will be stored locally and synched to the server when the connection is restored.',
              buttons: [
                {
                  text: 'OK',
                  id: 'cancel-button',
                  cssClass: 'cancel-alert-button',
                  handler: () => {
                    console.log('Cancel alert dialog and do nothing.')
                  },
                },
              ],
            });
            await alert.present();
          }
        }
      });
      
      try {
        await StatusBar.setStyle({ style: Style.Light });
        await StatusBar.show();
      } catch (e) {
        // NOT A BIG DEAL, KEEP MOVING
      }
    },
    methods: {
      ...mapActions('settings', {
        getAppSettings: 'getAppSettings',
        storeUser: 'storeUser',
        updateConnectivity: 'updateConnectivity'
      }),
      ...mapActions('queue', {
        loadQueue: 'loadQueue',
        loadGuidLibrary: 'loadGuidLibrary',
        processQueue: 'processQueue',
      }),
    }
  });
